<template>
  <div class="vidiconChannel">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio">
      <div class="contDio tableDivPage">
        <div class="title_name">
          <div>通道列表</div>
        </div>
        <el-table class="tableCont" :data="tableData" stripe :height="500" style="width:100%">
          <el-table-column prop="channelNo" label="通道号" width="80" show-overflow-tooltip/>
          <el-table-column label="通道名称">
            <template slot-scope="scope">
              <el-input type="text" v-model="scope.row.channelName" size="small" placeholder="请输入通道名称">
                <el-button type="primary" slot="append" @click="updateDeviceChannel(scope.row,'1')">确定</el-button>
              </el-input>
            </template>
          </el-table-column>
          <!-- <el-table-column label="云台类型" width="150">
            <template slot-scope="scope">
              <el-select v-model="scope.row.ptz" placeholder="请选择摄像机类型" size="small" @change="selectChange($event,scope.row)">
                <el-option v-for="item in dicObj.ipcShapeData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </template>
          </el-table-column> -->
          <el-table-column label="是否有云台" width="180">
            <template slot-scope="scope">
              <el-select v-model="scope.row.isPtz" placeholder="请选择" size="small" @change="selectChange($event,scope.row)">
                <el-option label="是" :value="1"/>
                <el-option label="否" :value="0"/>
              </el-select>
            </template>
          </el-table-column>
          <!-- <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.status=='1'" class="success">在线</span>
              <span v-else class="info">离线</span>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <span slot="footer">
        <el-button @click="closeDio()" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit:'',//弹窗标题
      dioType:'',//弹窗类型
      dioWidth:'',//弹窗宽度

      //form信息
      inform:{
        id:'',
      },
      
      tableData:[],//摄像头通道列表
    }
  },
  created() {
    vm = this
  },

  //方法
  methods:{
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      if(type == 'channel'){
        vm.dioTit = '查看通道'
        vm.inform.id = item.id
        vm.deviceChannelList(item)
      }
      vm.dioWidth = '740px'
      vm.isDio = true
    },
    //根据监控设备id查询摄像头列表
    async deviceChannelList (item){
      let dataObj = {
        'deviceMediaId': item.id
      }
      const res = await apiUrl.deviceChannelList(dataObj)
      if(res?.code==200){
        vm.tableData = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateDeviceChannel(item,type){
      let dataObj = {
        'id':item.id,
        'channelNo':item.channelNo,
        'deviceMediaId':item.deviceMediaId,
      }
      if(type=='1') dataObj.channelName = item.channelName
      // if(type=='2') dataObj.ptz = item.ptz
      if(type=='2') dataObj.isPtz = item.isPtz
      
      const res = await apiUrl.updateDeviceChannel(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
      }else{
        vm.$message.error(res.message)
      }
    },
    //下拉select选择名称
    selectChange (val,item){
      vm.updateDeviceChannel(item,'2')
      // let str = {}
      // str = vm.dicObj.ipcShapeData.find((li)=>{
      //   return li.dictValue === val;//筛选出匹配数据
      // });
      // item.currObj.ipcShapeName = str?.dictName
    },
    
    //关闭弹窗
    closeDio (formEl){
      // if (!formEl) return
      // formEl.resetFields()
      vm.isDio = false
    },
    
  }
}
</script>
<style lang='scss'>
.vidiconChannel{
  .contDio{
    &.h500{
      height: 500px;
      overflow: auto;
    }
    .el-button--small{
      margin: 0;
      padding: 5px;
      font-size: 14px;
      color: #409EFF;
      &:hover{
        color: #276bab;
      }
    }
    .el-input__inner{
      background-color: #031e44;
      border-color: #1d4975;
      color: #00c6ff;
      &::placeholder {
        color:  #00c6ff;
      }
    }
    .el-input-group__append, .el-input-group__prepend{
      background-color: #031e44;
      border: 1px solid #1d4975;
      color: #fff;
    }
  }
}
</style>