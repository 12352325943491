<template>
  <div class='safetyDeviceSensor'>
    <div class="">
      <div class="title_name">
        <div>设备总数：<span class="fs16 col00ffe">{{ total }}</span></div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:safety:device:sensor:add')"><i
              class="el-icon-circle-plus-outline"></i>添加
          </el-button>
          <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:safety:device:sensor:del')"><i
              class="el-icon-delete"></i>批量删除
          </el-button>
        </div>
      </div>
      <div :style="{height:tableH+'px',overflow:'auto'}">
        <el-checkbox-group v-model="tableActive">
          <div class="pageList">
            <div class="item" v-for="(item,index) in tableData" :key="index">
              <el-checkbox :label="item.id"></el-checkbox>
              <div class="liStatus tr">
                 <span title="在线状态" v-if="item.status==0"
                       :class="['status',{'success':false,'info':true}]">离线</span>
                <span title="在线状态" v-if="item.status==1"
                       :class="['status',{'success':true,'info':false}]">在线</span>
                <span title="设备告警" v-if="item.workState==1" class="status warning">
                    故障
                  </span>
              </div>
              <div class="liCont">
                <el-image fit="contain" :src="getIcon(item.senseType,item.isRelay)"></el-image>
                <div class="liRight">
                  <!-- <p :title="item.deviceSerial">{{item.deviceSerial}}</p> -->
                  <p class="name" :title="item.deviceName">{{ item.deviceName }}</p>
                  <p>{{ item.homeName }}</p>
                  <!-- <p>IO采集:<span class="success" v-if="item.isRelay=='1'">是</span><span class="info" v-else>否</span></p> -->
                  <p>设备类型:{{ item.senseType | statesFilter('1') }}</p>
                </div>
              </div>
              <div class="liTips" v-if="item.isRelay==1">
                <span><em>输入:</em>{{ item.inputNumber }}路</span>
                <span><em>输出:</em>{{ item.outputNumber }}路</span>
              </div>
              <div class="liTips" v-if="item.senseType==0&&item.targetValueList&&item.targetValueList.length>0">
                <span v-for="(item2,index) in item.targetValueList"
                      v-if="item2.name=='温度'||item2.name=='湿度'">
                  <template v-if="item2.name=='温度'"><em>T: </em>{{ item2.value }}{{ item2.unit }}</template>
                  <template v-if="item2.name=='湿度'"><em>RH: </em>{{ item2.value }}{{ item2.unit }}</template>
                </span>
              </div>
              <div class="liBtn">
                <div class="btn el-icon-edit" v-if="isAuth('safe:safety:device:sensor:edit')" title="编辑"
                     @click="openDio('edit',item)"></div>
                <div class="btn el-icon-setting" v-if="isAuth('safe:safety:device:sensor:config')&&item.isRelay==1"
                     title="配置" @click="openDio('config',item)"></div>
                <div class="btn el-icon-s-order" v-if="isAuth('safe:safety:device:sensor:detail')&&item.senseType==0"
                     title="详情"
                     @click="openDio('detail',item)"></div>
                <div class="btn el-icon-warning"
                     v-if="isAuth('safe:safety:device:sensor:rule')&&(item.senseType==0||item.senseType==2)"
                     title="告警规则"
                     @click="openDio('alarmRule',item)"></div>
                <div class="btn el-icon-delete" v-if="isAuth('safe:safety:device:sensor:del')" title="删除"
                     @click="openDio('del',item)"></div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div class="pageDiv">
        <el-pagination
            :current-page="curr"
            :page-size="limits"
            background
            layout="total,sizes, prev, pager, next, jumper"
            :page-sizes="[12, 20, 50, 100,200]"
            :total="total"
            @size-change="sizeChange"
            @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <sensor-add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="getList()"></sensor-add-edit>
    <!--告警规则-->
    <alarm-rule ref="alarmRuleDio" :dicObj="dicObj"></alarm-rule>
    <sensor-detail ref="detailDio" :dicObj="dicObj"></sensor-detail>
  </div>
</template>
<script>
import sensorAddEdit from './sensor-add-edit'
import {apiUrl} from '@/assets/js/api';
import qs from 'qs'
import {formatTime} from '@/utils/utils'
import alarmRule from "@/views/safety/device/electric/alarm-rule";
import SensorDetail from "@/views/safety/device/sensor/sensor-detail";

let d_icon1 = require('@/assets/images/yan_gan.png');
let d_icon2 = require('@/assets/images/ipc.png');
let d_icon3 = require('@/assets/images/wen_shi_du.png');
let d_icon4 = require('@/assets/images/shui_jin.png');
let d_icon5 = require('@/assets/images/IO.png');
let d_icon6 = require('@/assets/images/USR-N540.png');
var vm;
export default {
  props: ['dicObj', 'keyWord'],
  name: 'safety-device-sensor',
  data() {
    return {
      //分页
      curr: 1,//当前页
      limits: 12,//每页容量
      total: 0,//数据总数

      tableH: '',//表格高度
      tableActive: [],//表格选中
      tableData: [],
      senseTypeList:[],
      isAdmin: false,//是否是超级管理员
    }
  },
  components: {
    SensorDetail,
    sensorAddEdit,
    alarmRule,
  },
  watch: {},
  async created() {
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if (userInfo) {
      this.isAdmin = userInfo.isAdmin
    }
  },
  mounted() {
    window.onresize = () => {
      vm.initHeight()
    };

  },
  filters: {
    //状态过滤器
    statesFilter(val, type) {
      //传感器类型
      if (type == '1') {
        let result = vm.dicObj.typeDataSen.filter(item => item.dictValue == val)
        return result[0]?.dictName || '-'
      }
    },
  },
  //方法
  methods: {
    getIcon(type, isRelay) {
      if (isRelay == '1') {
        if (type == '5') {
          return d_icon6;
        } else {
          return d_icon5
        }
      } else {
        if (type == '0') {//温湿度
          return d_icon3
        } else if (type == '1') {//烟感
          return d_icon1
        } else if (type == '2') {//水浸
          return d_icon4
        } else if (type == '3') {//火焰
          return d_icon2
        }
      }
    },
    //打开弹窗
    openDio(type, item) {
      if (type == 'del') {
        vm.$confirm('确定删除该选项', '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              let arr = []
              if (item) {
                arr.push(item.id)
              }
              vm.deleteDeviceSense(arr)
            })
            .catch(() => {
            })
      } else {
        if (type == 'add' || type == 'edit') {
          vm.$refs.editDio.init(type, item,{isRelay: 0,senseType: null});//新增或修改
          // vm.$refs.editSwitchRef.init(type,item);//新增或修改
        } else if (type == 'config') {
          vm.$refs.configDio.init(type, item);//开关配置
        } else if (type == 'alarmRule') {
          vm.$refs.alarmRuleDio.init(type, item);//告警规则
        } else if (type == 'detail') {
          vm.$refs.detailDio.init(type, item);//详情
        }
      }
    },
    //批量删除
    batchDel() {
      if (vm.tableActive.length == 0) {
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        vm.deleteDeviceSense(vm.tableActive);//删除
      }).catch(() => {
      });
    },
    //删除
    async deleteDeviceSense(ids) {
      let dataObj = {
        'data': ids
      }
      const res = await apiUrl.deleteDeviceSense(dataObj)
      if (res?.code == 200) {
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      } else {
        vm.$message.error(res.message)
      }
    },
    //分页选择
    sizeChange(val) {
      vm.limits = val
      vm.getList(vm.curr, val)
    },
    currChange(val) {
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList(_curr, _limits) {
      vm.tableActive = [];
      //剔除senseType=[4,5]
      let dataObj = {
        'areaId': vm.$store.state.csType,
        'homeId': vm.$store.state.homeId,
        'delSenseType': [4, 5],//IO采集器和串口服务器
        'pageNo': _curr ? _curr : vm.curr,
        'pageSize': _limits ? _limits : vm.limits,
        ...vm.keyWord
      }
      const res = await apiUrl.deviceSensePage(dataObj)
      if (res?.code == 200) {
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr ? _curr : vm.curr
      } else {
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight() {
      var minWin = window.innerHeight;
      vm.tableH = minWin - 350
    }
  }
}
</script>
<style lang='scss'>
.safetyDeviceSensor {

}
</style>
