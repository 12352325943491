<template>
  <div class="protectionAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-form-item label="场所:" prop="homeId">
            <el-select v-model="inform.homeId" placeholder="请选择场所">
              <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称:" prop="deviceName">
            <el-input v-model="inform.deviceName" placeholder="请输入设备名称"></el-input>
          </el-form-item>
          <el-form-item label="序列号:" prop="deviceSerial">
            <el-input v-model="inform.deviceSerial" placeholder="请输入序列号"></el-input>
          </el-form-item>
          <el-form-item label="设备类型:" prop="deviceType">
            <el-select v-model="inform.deviceType" placeholder="请选择类型">
              <el-option v-for="(item,index) in dicObj.typeDataPro" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="品牌:" prop="deviceBrand">
            <el-select v-model="inform.deviceBrand" placeholder="请选择品牌">
              <el-option v-for="(item,index) in dicObj.brandData" :key="index" :label="item.dictName" :value="item.dictValue"/>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="deviceDescribe">
            <el-input type="textarea" v-model="inform.deviceDescribe" :rows="4"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {

      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        deviceName:'',//设备名称
        deviceSerial:'',//序列号
        deviceType:'',//设备类型
        deviceBrand:'',//设备品牌
        homeId:'',//场所ID
        deviceDescribe:'',//备注
      },
      rules :{
        deviceName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' },
        ],
        deviceSerial: [
          { required: true, message: '请输入序列号', trigger: 'blur' },
        ],
        deviceType: [
          { required: true, message: "请输入设备类型", trigger: "blur" }
        ],
        homeId: [
          { required: true, message: '请选择场所', trigger: 'change' }
        ],
      },
    }
  },
  components:{
    
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true

      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '新增设备'
        }else if(type == 'edit'){
          vm.dioTit = '编辑设备'
          // vm.inform = item
          vm.inform = {
            id:item.id,
            deviceName:item.deviceName,//设备名称
            deviceSerial:item.deviceSerial,//序列号
            deviceType:item.deviceType,//设备类型
            deviceBrand:item.deviceBrand,//设备品牌
            homeId:item.homeId,//场所ID
            deviceDescribe:item.deviceDescribe,//备注
          }
        }
      })
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.deviceSecurityInsert()
          }else if(vm.dioType=='edit'){
            vm.deviceSecurityUpdate()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })

      vm.isDio = false
    },
    //新增
    async deviceSecurityInsert(){
      let dataObj = {
        'areaId': vm.$store.state.csType,//区域id
        'homeId': vm.inform.homeId,//场所
        'deviceName':vm.inform.deviceName,//设备名称
        'deviceSerial': vm.inform.deviceSerial,//序列号
        'deviceType': vm.inform.deviceType,//设备类型
        'deviceBrand': vm.inform.deviceBrand,//品牌
        'deviceDescribe': vm.inform.deviceDescribe,//备注
      }
      const res = await apiUrl.deviceSecurityInsert(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async deviceSecurityUpdate(){
      let dataObj = {
        'id':vm.inform.id,
        'areaId': vm.$store.state.csType,//区域id
        'homeId': vm.inform.homeId,//场所
        'deviceName':vm.inform.deviceName,//设备名称
        'deviceSerial': vm.inform.deviceSerial,//序列号
        'deviceType': vm.inform.deviceType,//设备类型
        'deviceBrand': vm.inform.deviceBrand,//品牌
        'deviceDescribe': vm.inform.deviceDescribe,//备注
      }
      const res = await apiUrl.deviceSecurityUpdate(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.protectionAddEdit{
  .contDio{
    max-height: 500px;
    overflow: auto;
  }
}
</style>