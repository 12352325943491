<template>
  <div class="deviceVidiconAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio h500">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-row>
            <el-col :span="12">
            <el-form-item label="场所:" prop="homeId">
              <el-select v-model="inform.homeId" placeholder="请选择场所">
                <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="名称:" prop="deviceName">
              <el-input type="text" v-model="inform.deviceName" placeholder="请输入设备名称"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="设备编号:" prop="deviceSerial">
              <el-input type="text" v-model="inform.deviceSerial" placeholder="请输入设备编号"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="平台类型:" prop="platformType">
              <el-select v-model="inform.platformType" placeholder="请选择平台类型">
                <el-option v-for="(item,index) in dicObj.platformData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="设备标签:" prop="deviceTag">
              <el-select v-model="inform.deviceTag" placeholder="请选择设备标签">
                <el-option v-for="(item,index) in dicObj.tagData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="设备验证码:" prop="validateCode">
              <el-input type="text" v-model="inform.validateCode" placeholder="请输入设备验证码"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="固体版本号:" prop="deviceVersion">
              <el-input type="text" v-model="inform.deviceVersion" placeholder="请输入固体版本号"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="设备型号:" prop="deviceModel">
              <el-input type="text" v-model="inform.deviceModel" placeholder="请输入设备型号"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="24">
            <el-form-item label="安装位置:" prop="installPosition">
              <el-input type="text" v-model="inform.installPosition" placeholder="请输入安装位置"></el-input>
            </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        homeId:'',//场所id
        deviceName:'',//名称
        deviceSerial:'',//设备序列号
        validateCode:'',//设备验证码
        deviceModel:'',//设备型号
        deviceTag:'',//设备标签
        deviceVersion:'',//固体版本号
        platformType:'',//平台类型
        installPosition:'',//安装位置
      },
      
      rules :{
        homeId: [
          { required: true, message: '请选择场所', trigger: 'change' }
        ],
        deviceName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        deviceSerial: [
          { required: true, message: '请输入设备序列号', trigger: 'blur' }
        ],
        validateCode: [
          { required: true, message: '请摄入设备验证码', trigger: 'blur' }
        ],
        deviceTag: [
          { required: true, message: '请选择设备标签', trigger: 'change' }
        ],
        platformType: [
          { required: true, message: '请选择平台类型', trigger: 'change' }
        ],
      },
    }
  },
  created(){
    vm = this
    
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '740px'
      vm.isDio = true
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '添加监控设备'
        }else if(type == 'edit'){
          vm.dioTit = '修改监控设备'
          vm.inform = {
            id:item.id,
            homeId:item.homeId,//场所id
            deviceName:item.deviceName,//名称
            deviceSerial:item.deviceSerial,//设备序列号
            validateCode:item.validateCode,//设备验证码
            deviceModel:item.deviceModel,//设备型号
            deviceTag:item.deviceTag.toString(),//设备标签
            platformType:item.platformType.toString(),//平台类型
            deviceVersion:item.deviceVersion,//固体版本号
            installPosition:item.installPosition,//安装位置
          }
        }
      })
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addDeviceMedia()
          }else{
            vm.updateDeviceMedia()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.isDio = false
    },
    
    //新增
    async addDeviceMedia(){
      // if(this.inform.list.length == 0) return this.$message.error('请选择设备到右侧！')
      let dataObj = {
        'homeId':vm.inform.homeId,//场所id
        'deviceName':vm.inform.deviceName,//名称
        'deviceSerial':vm.inform.deviceSerial,//设备序列号
        'validateCode':vm.inform.validateCode,//设备验证码
        'deviceModel':vm.inform.deviceModel,//设备型号
        'deviceTag':vm.inform.deviceTag,//设备标签
        'deviceVersion':vm.inform.deviceVersion,//固体版本号
        'platformType':vm.inform.platformType,//平台类型
        'installPosition':vm.inform.installPosition,//安装位置
      }
      const res = await apiUrl.addDeviceMedia(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateDeviceMedia(){
      let dataObj = {
        'id':vm.inform.id,
        'homeId':vm.inform.homeId,//场所id
        'deviceName':vm.inform.deviceName,//名称
        'deviceSerial':vm.inform.deviceSerial,//设备序列号
        'validateCode':vm.inform.validateCode,//设备验证码
        'deviceModel':vm.inform.deviceModel,//设备型号
        'deviceTag':vm.inform.deviceTag,//设备标签
        'deviceVersion':vm.inform.deviceVersion,//固体版本号
        'platformType':vm.inform.platformType,//平台类型
        'installPosition':vm.inform.installPosition,//安装位置
      }
      const res = await apiUrl.updateDeviceMedia(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    
  }
}
</script>
<style lang='scss'>
.deviceVidiconAddEdit{
  .h500{
    max-height:500px;
    overflow:auto;
  }
}
</style>