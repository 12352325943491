<template>
  <div class="protectionIssue">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <template v-if="dioType=='1'">
          <el-form-item label="下发标签:" prop="flag">
            <el-select v-model="inform.flag" placeholder="请选择标签">
              <el-option label="清空人员" value="2"/>
              <el-option label="清空人员指纹" value="3"/>
            </el-select>
          </el-form-item>
          </template>
          <template v-if="dioType=='2'">
          <el-form-item label="下发标签:" prop="flag">
            <el-select v-model="inform.flag" placeholder="请选择标签" @change="flagChange">
              <el-option label="下发人员信息" value="4"/>
              <el-option label="下发指纹信息" value="5"/>
            </el-select>
          </el-form-item>
          </template>
        </el-form>
        <div class="tableDivPage" v-if="dioType=='2'">
          <el-table ref="userTableRef" class="tableCont" :data="userList" stripe :height="300" style="width:100%" @selection-change="tableSelectionChange">
            <el-table-column type="selection" :selectable="selectable" width="55"></el-table-column>
            <el-table-column label="账号" prop="account"/>
            <el-table-column label="工号" prop="personNo"/>
            <el-table-column label="企业" prop="depaName"/>
            <el-table-column prop="personName" label="姓名"/>
            <el-table-column prop="fingerprintNum" label="指纹"/>
          </el-table>
        </div>
        
      </div>
      <span slot="footer">
        <div>
          <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
          <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
var vm;
export default {
  data() {
    return {
      isDio :false,//弹窗类型
      dioTit:'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        deviceIds:[],
        flag:'',//下发标签
        userIds:[],
      },
      userList:[],//用户数据
      
      rules:{
        flag: [
          { required: true, message: '请选择下发标签', trigger: 'change' },
        ],
        
      },
    }
  },
  components:{
    
  },
  created(){
    vm = this
  },
  mounted(){
  },

  //方法
  methods:{
    //初始化
    init(type,ids) {
      vm.dioType = type
      vm.isDio = true
      
      vm.inform.deviceIds=ids//选中的表格数据ids
      vm.$nextTick(() => {
        if(type=='1'){
          vm.dioTit = '下发信息'
          vm.dioWidth = '440px'
        }else if(type == '2'){
          vm.dioWidth = '840px'
          vm.dioTit = '下发用户相关信息'
          vm.listSoldierVoWithSendInfo()
          
        }
      })
    },
    //标签改变
    flagChange(val){
      //重置表格选中数据
      this.$refs.userTableRef.clearSelection();
    },
    //校验行是否禁用
    selectable: function(row, index){
      //判断是否没有人脸数据并且是下发人脸标签
      // if(row.hasFace=='0'&& this.inform.flag=='2'){
      //   return false
      // }else 
      if(row.fingerprintNum==0 && this.inform.flag=='5'){//判断是否没有指纹数据并且是下发指纹标签
        return false
      }else{
        return true
      }
    },
    //表格多选
    tableSelectionChange(val) {
      vm.inform.userIds = val;
    },
    //过滤单位
    formatDepat(row, column){
      if(row.departments&&row.departments.length>0){
        let arr = []
        for(var i in row.departments){
          arr.push(row.departments[i].depaName)
        }
        return arr.join(',')
      }else{
        return '-'
      }
    },
    //过滤姓名
    formatName(row, column){
      if(row.user&&Object.keys(row.user).length>0){
        return row.user.account
      }else{
        return '-'
      }
    },
    //获取用户列表
    async listSoldierVoWithSendInfo (){
      const res = await apiUrl.personList({})
      if(res?.code==200){
        vm.userList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='1'){
            if(vm.inform.flag=='2'||vm.inform.flag=='3'){//判断是清空人员或清空人员指纹
              this.$confirm(`确定下发${vm.inform.flag=='2'?'清空人员':'清空人员指纹'}？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                vm.deviceSecurityControlSend()
              }).catch(() => {});
            }
          }else if(vm.dioType=='2'){
            vm.deviceSecurityControlSend()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl) {
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
        vm.inform.deviceIds = []
        vm.inform.flag = ''
        vm.inform.userIds = []
      // })
      vm.isDio = false
    },
    //下发信息
    async deviceSecurityControlSend (){
      let arr = []
      if(vm.dioType=='2'){
        if(vm.inform.userIds.length==0){
          vm.$message.warning('请勾选需要操作的数据')
          return false
        }
        for(var i in vm.inform.userIds){
          arr.push(vm.inform.userIds[i].id)
        }
      }
      //如果是有数据参数，说明是下发开门的类型，需要提交授权管理员的指纹或密码
      let dataObj = {
        'flag':vm.inform.flag,
        'ids':vm.inform.deviceIds
      }
      if(vm.dioType=='2'){
        dataObj.personIds = arr
      }
      const res = await apiUrl.deviceSecurityControlSend(dataObj)
      if(res?.code==200){
        // vm.$message.success('创建成功！')
        this.$message({
          type:'success',
          dangerouslyUseHTMLString: true,
          message: res.message,
          duration:10000,
          showClose:true,
        });
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    

  }
}
</script>
<style lang='scss'>
.protectionIssue{
  .h330{
    height: 330px;
    overflow: auto;
  }
  .list{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 0;
    .item{
      width: 23%;
      height: auto;
      flex-shrink: 0;
      margin: 0 1% 10px;
      box-sizing: border-box;
      border: 1px solid #000;
      border-radius: 6px;
      padding: 10px;
      position: relative;
      overflow: hidden;
      .num{
        position: absolute;
        left: 0;
        top: 0;
        padding:2px 5px;
        background-color: #E6A23C;
        // border-radius: 6px;
      }
      .imgDiv{
        img{
          width: 100%;
        }
      }
    }
  }
}
</style>