<template>
  <div class='safetyDeviceElectric'>
    <div class="">
      <div class="title_name">
        <div>设备总数：<span class="fs16 col00ffe">{{ total }}</span></div>
        <div>
          <el-button type="text" @click="openDio('addQuick')" v-if="isAuth('safe:safety:device:electric:add')"><i
              class="el-icon-circle-plus-outline"></i>快速添加
          </el-button>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:safety:device:electric:add')"><i
              class="el-icon-circle-plus-outline"></i>添加
          </el-button>
          <!--          <el-button type="text" @click="openDio('statistics')" v-if="isAuth('safe:safety:device:electric:add')"><i class="icon icon-dialutu"></i>电气线路</el-button>-->
          <el-button type="text" @click="batchClick('open')" class="success"><i class="icon icon-duankai"></i>分闸
          </el-button>
          <el-button type="text" @click="batchClick('close')" class="error"><i class="icon icon-bihe"></i>合闸
          </el-button>
          <el-button type="text" class="error" @click="batchClick('del')"
                     v-if="isAuth('safe:safety:device:electric:del')"><i class="el-icon-delete"></i>批量删除
          </el-button>
        </div>
      </div>
      <div :style="{height:tableH+'px',overflow:'auto'}">
        <el-checkbox-group v-model="tableActive">
          <div class="pageList">
            <div class="item" v-for="(item,index) in tableData" :key="index">
              <el-checkbox :label="item.id"></el-checkbox>
              <div class="liStatus tr">
                <span title="在线状态" v-if="item.status!=null&&item.statusName!=null"
                      :class="['status',{'success':item.status=='1','info':item.status=='0'}]">{{
                    item.statusName
                  }}</span>
                <span title="运行状态" v-if="item.runStatus!=null&&item.runStatusName!=null"
                      :class="['status',{'success':item.runStatus=='1','warning':item.runStatus=='0'}]">{{
                    item.runStatusName
                  }}</span>
                <span title="设备告警" v-if="item.alarmCategory!=null&&item.alarmCategoryName!=null" class="status warning">{{
                    item.alarmCategoryName
                  }}</span>
                <span title="电闸状态" v-if="item.devType==1&&item.switchStatus!=null&&item.switchStatusName!=null"
                      class="status success">{{ item.switchStatusName }}</span>
              </div>
              <div class="liCont">
                <el-image fit="contain" :src="getIcon(item.icon,item.id)"></el-image>
                <div class="liRight">
                  <!-- <p :title="item.devNo">{{item.devNo}}</p> -->
                  <p class="name" :title="item.devName">{{ item.devName }}</p>
                  <p>{{ item.homeName }}</p>
                  <p>设备类型:{{ item.devType | statesFilter('5') }}</p>
                  <!-- <p :title="item.ip">IP:{{ item.ip }}</p>
                  <p :title="item.marketCode">市场编码:{{ item.marketCode }}</p> -->
                </div>
              </div>
              <div class="liTips" v-if="item.attributes &&item.attributes.length>0">
                <span v-for="(item2,index) in item.attributes"
                      v-if="item2.name=='电流'||item2.name=='电压'||item2.name=='设备功率'">
                  <template v-if="item2.name=='电流'"><em>I:</em>{{ item2.value }}{{ item2.unit }}</template>
                  <template v-if="item2.name=='电压'"><em>U:</em>{{ item2.value }}{{ item2.unit }}</template>
                  <template v-if="item2.name=='设备功率'"><em>P:</em>{{ item2.value }}{{ item2.unit }}</template>
                </span>
              </div>
              <div class="liBtn">
                <div class="btn el-icon-edit" v-if="isAuth('safe:safety:device:electric:edit')"
                     title="编辑"
                     @click="openDio('edit',item)"></div>
                <div class="btn icon icon-kaiguan1" v-if="item.devType!='0'" title="开/合闸"
                     @click="openDio('switch',item)"></div>
                <div class="btn el-icon-s-order" v-if="isAuth('safe:safety:device:electric:detail')" title="详情"
                     @click="openDio('detail',item)"></div>
                <div class="btn el-icon-warning" v-if="isAuth('safe:safety:device:electric:rule')" title="告警规则"
                     @click="openDio('alarmRule',item)"></div>
                <div class="btn el-icon-delete" v-if="isAuth('safe:safety:device:electric:del')" title="删除"
                     @click="openDio('del',item)"></div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div class="pageDiv">
        <el-pagination
            :current-page="curr"
            :page-size="limits"
            background
            layout="total,sizes, prev, pager, next, jumper"
            :page-sizes="[12, 20, 50, 100,200]"
            :total="total"
            @size-change="sizeChange"
            @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <electric-add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="getList()"></electric-add-edit>
    <!--快速同步创建-->
    <electric-add-quick ref="quickAddDio" :dicObj="dicObj" @quickAddEditSucc="getList()"></electric-add-quick>
    <!--详情-->
    <electric-detail ref="detailDio" :dicObj="dicObj"></electric-detail>
    <!--    &lt;!&ndash;电气线路&ndash;&gt;
         <electric-statistics ref="statisticsRef"></electric-statistics>-->
    <!--告警规则-->
    <alarm-rule ref="alarmRuleDio" :dicObj="dicObj"></alarm-rule>

  </div>
</template>
<script>
// import electricStatistics from './electric-statistics'
import alarmRule from './alarm-rule'
import electricDetail from './electric-detail'
import electricAddEdit from './electric-add-edit.vue'
import {apiUrl, globalStr} from '@/assets/js/api';
import qs from 'qs'
import {formatTime} from '@/utils/utils'
import ElectricAddQuick from "@/views/safety/device/electric/electric-add-quick";

var vm;
export default {
  props: ['dicObj', 'keyWord'],
  name: 'safety-device-electric',
  data() {
    return {
      //分页
      curr: 1,//当前页
      limits: 12,//每页容量
      total: 0,//数据总数

      tableH: '',//表格高度
      tableActive: [],//表格选中
      tableData: [],
      isAdmin: false,//是否是超级管理员
    }
  },
  components: {
    ElectricAddQuick,
    electricAddEdit,
    electricDetail,
    alarmRule,
  },
  watch: {
    '$store.state.wsMsg': function (newVal, oldVal) {
      this.getSkMsg(newVal)
    },
  },

  async created() {
    vm = this
    vm.initHeight()
  },
  mounted() {
    window.onresize = () => {
      vm.initHeight()
    }
  },
  filters: {
    //状态过滤器
    statesFilter(val, type) {
      //在线状态
      if (type == '1') {
        let result = vm.dicObj.statusData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //连接状态
      if (type == '2') {
        let result = vm.dicObj.connectData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //告警状态
      if (type == '3') {
        let result = vm.dicObj.alarmData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //合闸状态
      if (type == '4') {
        let result = vm.dicObj.tripData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //设备类型
      if (type == '5') {
        let result = vm.dicObj.typeDataElt.filter(item => item.dictValue == val)
        return result[0]?.dictName
      }
    },
  },
  //方法
  methods: {
    //状态过滤器
    statesFilter(val, type) {
      //在线状态
      if (type == '1') {
        let result = vm.dicObj.statusData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //连接状态
      if (type == '2') {
        let result = vm.dicObj.connectData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //告警状态
      if (type == '3') {
        let result = vm.dicObj.alarmData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //合闸状态
      if (type == '4') {
        let result = vm.dicObj.tripData.filter(item => item.dictValue == val)
        return result[0].dictName
      }
      //设备类型
      if (type == '5') {
        let result = vm.dicObj.typeDataElt.filter(item => item.dictValue == val)
        return result[0]?.dictName
      }
    },
    //获取ws数据
    getSkMsg(res) {
      //判断是设备状态通知(上、下线)
      if (res?.type == 'deviceStatus') {
        let str = vm.tableData?.find(item => item.deviceId == res.data.deviceData?.deviceId)
        if (str) {
          str.status = res.data.deviceData?.deviceStatus
          str.statusName = vm.statesFilter(str.status, '1');
        }
      }
      //判断是设备告警通知
      if (res?.type == 'deviceAlarm') {
        let str = vm.tableData?.find(item => item.deviceId == res.data.deviceData?.deviceId)
        if (str) {
          str.alarmCategory = res.data.alarmData?.alarmCategory
          str.alarmCategoryName = vm.statesFilter(str.alarmCategory, '3');
        }
      }
      //判断是设备合闸通知
      if (res?.type == 'deviceJump') {
        let str = vm.tableData?.find(item => item.deviceId == res.data.deviceData?.deviceId)
        if (str) {
          str.switchStatus = res.data.deviceData?.trip;
          str.currentSwitchStatus =  str.switchStatus;
          const switchStatusName = res.data.deviceData.tripName;
          str.switchStatusName = (switchStatusName == null || switchStatusName == '') ? vm.statesFilter(str.switchStatus, '4') : switchStatusName;
        }
      }
    },
    getIcon(icon, id) {
      //请求压缩后的图片
      return `${globalStr}/fileView/${icon}?_t=` + id;
    },
    //打开弹窗
    openDio(type, item) {
      if (type == 'del') {
        vm.$confirm('确定删除该选项', '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              let arr = []
              if (item) {
                arr.push(item.id)
              }
              vm.deleteDevice(arr)
            })
            .catch(() => {
            })
      } else if (type == 'switch') {//开、合闸
        vm.$confirm(`是否执行${item.currentSwitchStatus == 0 ? '合闸' : '分闸'}指令，请谨慎操作？`, '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              vm.batchControlDevice([item.id], item.currentSwitchStatus == 0 ? 1 : 0)
            })
            .catch(() => {
            })
      } else if (type == 'statistics') {//设备统计
        vm.$refs.statisticsRef.init(type);
      } else if (type == 'add' || type == 'edit') {
        vm.$refs.editDio.init(type, item, {devType: '1'});//新增或修改
      } else if (type == 'detail') {
        vm.$refs.detailDio.init(type, item);//详情
      } else if (type == 'alarmRule') {
        vm.$refs.alarmRuleDio.init(type, item);//告警规则
      } else if (type == 'addQuick') {
        vm.$refs.quickAddDio.init(type, {devType: '1'});//快速添加
      }
      //  else if(type=='switch'){//分闸、合闸
      //   vm.batchControlDevice([item.id],item.switchStatus==0?1:0)
      // }
    },
    //判断数组中所有类型是否一致
    isTypeEqual(arr) {
      if (arr.length === 0) {
        return true; // 数组为空时返回true
      }
      for (let i = 0; i < arr.length; i++) {
        //判断设备类型不为网关
        if (arr[i].devType != '1') {
          return false; // 如果有一个对象的type不是0，就返回false
        }
      }
      return true; // 所有对象的type都相同，返回true
    },
    //批量操作
    batchClick(type) {
      if (vm.tableActive.length == 0) {
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      let msg = ''
      if (type == 'del') msg = '是否确定要删除所选设备，删除后不可恢复？'
      if (type == 'close') {
        msg = '是否执行关闭指令，请谨慎操作？'
        let newArr = vm.tableData.filter(obj => vm.tableActive.includes(obj.id));
        if (vm.isTypeEqual(newArr) == false) {//判断设备类型
          vm.$message.warning('开合闸操作不能有网关设备')
          return
        }
      }
      if (type == 'open') {
        msg = '是否执行开启指令，请谨慎操作？'
        let newArr = vm.tableData.filter(obj => vm.tableActive.includes(obj.id));
        if (vm.isTypeEqual(newArr) == false) {//判断设备类型
          vm.$message.warning('开合闸操作不能有网关设备')
          return
        }
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (type == 'del') {
          vm.deleteDevice(vm.tableActive);//删除
        } else if (type == 'close') {
          vm.batchControlDevice(vm.tableActive, 1)
        } else if (type == 'open') {
          vm.batchControlDevice(vm.tableActive, 0)
        }

      }).catch(() => {
      });
    },
    //开关操作
    async batchControlDevice(arr, type) {
      let dataObj = {
        'devId': arr.join(','),
        'state': type
      }
      const res = await apiUrl.batchControlDevice(dataObj)
      if (res?.code == 200) {
        vm.$message.success('操作成功')
        vm.tableActive = []
        vm.getList()
      } else {
        vm.$message.error(res.message)
      }
    },
    //删除
    async deleteDevice(ids) {
      let dataObj = {
        'ids': ids.join(',')
      }
      const res = await apiUrl.deleteDevice(qs.stringify(dataObj))
      if (res?.code == 200) {
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.tableActive = []
        vm.getList()
      } else {
        vm.$message.error(res.message)
      }
    },
    //分页选择
    sizeChange(val) {
      vm.limits = val
      vm.getList(vm.curr, val)
    },
    currChange(val) {
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList(_curr, _limits) {
      vm.tableActive = [];
      let dataObj = {
        'areaId': vm.$store.state.csType,
        'homeId': vm.$store.state.homeId,
        'devType': 1,
        'pageNo': _curr ? _curr : vm.curr,
        'pageSize': _limits ? _limits : vm.limits,
        ...vm.keyWord
      }
      const res = await apiUrl.devicePageList(dataObj)
      if (res?.code == 200) {
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr ? _curr : vm.curr
      } else {
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight() {
      var minWin = window.innerHeight;
      vm.tableH = minWin - 350
    }
  }
}
</script>
<style lang='scss'>
.safetyDeviceElectric {

}
</style>
