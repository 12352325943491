<template>
  <div class='safetyDeviceProtection'>
    <div class="">
      <div class="title_name">
        <div>设备总数：<span class="fs16 col00ffe">{{ total }}</span></div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:safety:device:protection:add')"><i
              class="el-icon-circle-plus-outline"></i>添加
          </el-button>
          <el-button type="text" @click="batchClick('pull')" class="success"><i class="icon icon-daoru"></i>拉取指纹
          </el-button>
          <el-button type="text" @click="batchClick('down','1')"><i class="el-icon-download"></i>下发</el-button>
          <el-button type="text" @click="batchClick('down','2')"><i class="el-icon-download"></i>下发人员信息</el-button>
          <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:safety:device:protection:del')"><i
              class="el-icon-delete"></i>批量删除
          </el-button>
        </div>
      </div>
      <div :style="{height:tableH+'px',overflow:'auto'}">
        <el-checkbox-group v-model="tableActive">
          <div class="pageList">
            <div class="item" v-for="(item,index) in tableData" :key="index">
              <el-checkbox :label="item.id"></el-checkbox>
              <div class="liStatus tr">
                <span class="status success" v-if="item.deviceStatus=='1'">在线</span>
                <span class="status info" v-else>离线</span>
              </div>
              <div class="liCont">
                <el-image fit="contain" :src="getIcon(item.deviceType)"></el-image>
                <div class="liRight">
                  <!-- <p :title="item.deviceSerial">{{item.deviceSerial}}</p> -->
                  <p class="name" :title="item.deviceName">{{ item.deviceName }}</p>
                  <p>{{ item.homeName }}</p>
                  <p>设备类型:{{ item.deviceTypeName }}</p>
                </div>
              </div>
              <div class="liBtn">
                <div class="btn el-icon-edit" v-if="isAuth('safe:safety:device:protection:edit')" title="编辑"
                     @click="openDio('edit',item)"></div>
                <div class="btn el-icon-s-order"
                     v-if="(item.deviceType=='2'||item.deviceType=='10')&&isAuth('safe:safety:device:protection:detail')"
                     title="详情" @click="openDio('detail',item)"></div>
                <div class="btn el-icon-delete" v-if="isAuth('safe:safety:device:protection:del')" title="删除"
                     @click="openDio('del',item)"></div>
                <div class="btn el-icon-unlock"
                     v-if="(item.deviceType=='10'||item.deviceType=='2')&&isAuth('safe:safety:device:protection:del')" title="远程开门"
                     @click="openDoor(item)"></div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div class="pageDiv">
        <el-pagination
            :current-page="curr"
            :page-size="limits"
            background
            layout="total,sizes, prev, pager, next, jumper"
            :page-sizes="[12, 20, 50, 100,200]"
            :total="total"
            @size-change="sizeChange"
            @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <protection-add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="getList()"></protection-add-edit>
    <!--下发-->
    <protection-issue ref="issueDio" @addEditSucc="getList()"></protection-issue>
    <!--详情-->
    <protection-detail ref="detailDio" @addEditSucc="getList()"></protection-detail>
  </div>
</template>
<script>
import protectionAddEdit from './protection-add-edit.vue'
import protectionIssue from './protection-issue.vue'
import protectionDetail from './protection-detail.vue'
import {apiUrl} from '@/assets/js/api';
import qs from 'qs'
import {formatTime} from '@/utils/utils'

let d_icon1 = require('@/assets/images/chao_nao.png');
let d_icon2 = require('@/assets/images/men_jin.png');
let d_icon3 = require('@/assets/images/door-magnet.png');
var vm;
export default {
  props: ['dicObj', 'keyWord'],
  name: 'safety-device-protection',
  data() {
    return {
      //分页
      curr: 1,//当前页
      limits: 12,//每页容量
      total: 0,//数据总数

      tableH: '',//表格高度
      tableActive: [],//表格选中
      tableData: [],

      isAdmin: false,//是否是超级管理员
    }
  },
  components: {
    protectionAddEdit,
    protectionIssue,
    protectionDetail,
  },
  watch: {
    '$store.state.wsMsg': function (newVal, oldVal) {
      this.getSkMsg(newVal)
    },
  },
  async created() {
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if (userInfo) {
      this.isAdmin = userInfo.isAdmin
    }
  },
  async mounted() {
    window.onresize = () => {
      vm.initHeight()
    }
  },
  filters: {},
  //方法
  methods: {
    getIcon(type) {
      if (type == '3') {
        return d_icon1
      } else if (type == '2') {
        return d_icon2
      } else if (type == '10') {
        return d_icon3
      }
    },
    //获取ws数据
    getSkMsg(res) {
      //判断是设备状态通知(上、下线)
      if (res?.type == 'deviceStatus') {
        let str = vm.tableData?.find(item => item.id == res.data.deviceData?.deviceId)
        if (str) {
          str.deviceStatus = res.data.deviceData?.deviceStatus
        }
      }
    },

    async openDoor(item) {
      const res = await apiUrl.doorOpenControl(item.id);
      if (res?.code == 200) {
        vm.$message.success('操作成功')
      } else {
        vm.$message.error(res.message)
      }
    },
    //打开弹窗
    openDio(type, item) {
      if (type == 'del') {
        vm.$confirm('确定删除该选项', '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              let arr = []
              if (item) {
                arr.push(item.id)
              }
              vm.deviceSecurityDelete(arr)
            })
            .catch(() => {
            })
      } else {
        if (type == 'add' || type == 'edit') {
          vm.$refs.editDio.init(type, item);//新增或修改
        } else if (type == 'detail') {
          vm.$refs.detailDio.init(type, item);//详情
        }
      }
    },
    //判断数组中所有类型是否一致
    isTypeEqual(arr, type) {
      if (arr.length === 0) {
        return true; // 数组为空时返回true
      }
      for (let i = 0; i < arr.length; i++) {
        //判断设备类型不为门禁且状态为离线
        if (type == '1') {
          if (arr[i].deviceType != '2') {
            return false; // 如果有一个对象的type不是1，就返回false
          }
        } else if (type == '2') {
          if (arr[i].deviceStatus != '1') {
            return false; // 如果有一个对象的type不是1，就返回false
          }
        }

      }
      return true; // 所有对象的type都相同，返回true
    },
    //批量操作
    batchClick(type, type2) {
      if (vm.tableActive.length == 0) {
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      let newArr = vm.tableData.filter(obj => vm.tableActive.includes(obj.id));
      if (vm.isTypeEqual(newArr, '1') == false) {//判断门禁类型
        vm.$message.warning('请勾选在线状态的门禁类型数据')
        return
      }
      if (vm.isTypeEqual(newArr, '2') == false) {//判断在线状态
        vm.$message.warning('请勾选在线状态的门禁类型数据')
        return
      }
      //拉取指纹
      if (type == 'pull') {
        if (newArr.length > 1) {
          vm.$message.warning('只能选择单个设备拉取指纹数据')
          return
        }
        vm.pullFingerprint(vm.tableActive[0])
      } else if (type == 'down') {
        vm.$refs.issueDio.init(type2, vm.tableActive)
      }
    },
    //单个设备拉取指纹
    async pullFingerprint(id) {
      const res = await apiUrl.pullFingerprint(id)
      if (res?.code == 200) {
        vm.$message.success('拉取指纹成功')
        vm.getList()
      } else {
        vm.$message.error(res.message)
      }
    },
    //批量删除
    batchDel() {
      if (vm.tableActive.length == 0) {
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        vm.deviceSecurityDelete(vm.tableActive);//删除
      }).catch(() => {
      });
    },
    //删除
    async deviceSecurityDelete(ids) {
      let dataObj = {
        'data': ids
      }
      const res = await apiUrl.deviceSecurityDelete(dataObj)
      if (res?.code == 200) {
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      } else {
        vm.$message.error(res.message)
      }
    },
    //分页选择
    sizeChange(val) {
      vm.limits = val
      vm.getList(vm.curr, val)
    },
    currChange(val) {
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList(_curr, _limits) {
      vm.tableActive = []
      let dataObj = {
        'areaId': vm.$store.state.csType,
        'homeId': vm.$store.state.homeId,
        'deviceType': vm.keyWord.type,
        'keyword': vm.keyWord.name,
        'pageNo': _curr ? _curr : vm.curr,
        'pageSize': _limits ? _limits : vm.limits
      }
      const res = await apiUrl.deviceSecurityListPage(dataObj)
      if (res?.code == 200) {
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr ? _curr : vm.curr
      } else {
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight() {
      var minWin = window.innerHeight;
      vm.tableH = minWin - 350
    }
  }
}
</script>
<style lang='scss'>
.safetyDeviceProtection {

}
</style>
