<template>
  <div class="protectionDetail">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio">
      <el-tabs v-model="tabsActive" @tab-click="tabsClick" type="border-card">
        <el-tab-pane v-if="deviceType==2" label="人员信息" name="1">
          <div class="tableDivPage">
            <el-table class="tableCont" :data="tableData" stripe :height="400" style="width:100%">
              <el-table-column prop="personNo" label="编号"/>
              <el-table-column prop="personName" label="姓名"/>
              <el-table-column label="是否管理员">
                <template slot-scope="scope">
                  <span class="success" v-if="scope.row.isAdmin == 1">是</span>
                  <span v-else>否</span>
                </template>
              </el-table-column>
              <el-table-column prop="fingerprintNum" label="指纹"/>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="操作记录" name="2">
          <div class="tableDivPage">
            <el-form :model="logKeyWord" label-width="100px" inline @submit.native.prevent size="small">
              <el-form-item label="操作时间:" prop="time">
                <el-date-picker v-model="logKeyWord.time" value-format="yyyy-MM-dd"
                                :default-time="['00:00:00', '23:59:59']" @change="deviceLogs(1)" type="daterange"
                                range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"/>
              </el-form-item>
              <el-form-item label="">
                <el-button type="primary" size="small" @click="deviceLogs(1)">搜索</el-button>
              </el-form-item>
            </el-form>
            <el-table class="tableCont" :data="logData" stripe :height="400" style="width:100%">
              <el-table-column type="index" label="序号" width="60"/>
              <el-table-column prop="content" label="日志说明" show-overflow-tooltip/>
              <el-table-column prop="userName" label="操作人员"/>
              <el-table-column :formatter="timeFormat" label="操作时间" width="240"/>
            </el-table>
            <div class="pageDiv">
              <el-pagination
                  :current-page="curr2"
                  :page-size="limits2"
                  background
                  layout="total,sizes, prev, pager, next, jumper"
                  :page-sizes="[10, 20, 50, 100,200]"
                  :total="total2"
                  @size-change="sizeChange2"
                  @current-change="currChange2"
              />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {delChildren, getUUID, formatTime} from '@/utils/utils'

let d_icon1 = require('@/assets/images/W3QF13.png');
let d_icon2 = require('@/assets/images/sukezha.png');
var vm;
export default {
  props: ['dicObj'],
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度
      deviceType: 2,
      tabsActive: '1',//tabs
      //form信息
      inform: {
        id: '',
      },

      logKeyWord: {
        time: '',
      },
      //分页
      curr2: 1,//当前页
      limits2: 10,//每页容量
      total2: 0,//数据总数
      tableData: [],//列表数据
      logData: [],//日志数据
    }
  },
  created() {
    vm = this
  },
  //方法
  methods: {
    //初始化
    init(type, item) {
      vm.dioType = type
      vm.dioWidth = '840px'
      vm.isDio = true
      vm.deviceType = item.deviceType;
      vm.id = item.id;
      console.log(type, item);
      vm.$nextTick(() => {
        if (type == 'detail') {
          vm.dioTit = '详情'
          vm.inform = item
          if (item.deviceType == 2) {
            vm.endPersonList(item.id);
            vm.tabsActive = '1';
          } else {
            vm.tabsActive = '2';
            vm.deviceLogs(1, vm.limits2)
          }
        }
      })
    },
    //分页选择
    sizeChange2(val) {
      vm.limits2 = val
      vm.deviceLogs(vm.curr2, vm.limits2)
    },
    currChange2(val) {
      vm.curr2 = val
      vm.deviceLogs(val, vm.limits2)
    },

    //tabs切换
    tabsClick(tab, event) {
      if (this.tabsActive == '1') {
        vm.endPersonList(vm.id)
      } else {
        vm.deviceLogs(1, vm.limits2)
      }
    },

    //获取操作记录
    async deviceLogs(_curr, _limits) {
      let dataObj = {
        'homeId': vm.inform.homeId,
        'devId': vm.inform.deviceId,
        'pageNo': _curr ? _curr : vm.curr2,
        'pageSize': _limits ? _limits : vm.limits2,
        'startTime': vm.logKeyWord.time ? vm.logKeyWord.time[0] : '',
        'endTime': vm.logKeyWord.time ? vm.logKeyWord.time[1] : '',
      }
      const res = await apiUrl.deviceLogs(dataObj)
      if (res?.code == 200) {
        vm.logData = res.data.list
        vm.total2 = parseInt(res.data.totalSize)
        vm.curr2 = _curr ? _curr : vm.curr2
      } else {
        vm.$message.error(res.message)
      }
    },
    //获取终端人员
    async endPersonList(id) {
      let dataObj = {
        'endId': id
      }
      const res = await apiUrl.endPersonList(qs.stringify(dataObj))
      if (res?.code == 200) {
        vm.tableData = res.data
      } else {
        vm.$message.error(res.message)
      }
    },
    //过滤时间
    timeFormat(row, column) {
      return row.createTime ? formatTime(row.createTime, 'Y-M-D h:m:s') : '-'
    },
    //关闭弹窗
    closeDio() {
      vm.inform = {
        id: '',
      }
      vm.tableData = []
      vm.isDio = false
    },
  }
}
</script>
<style lang='scss'>
.protectionDetail {
  .contDio {
    max-height: 500px;
    overflow: auto;
  }
}
</style>