<template>
  <div class="airAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="130px" size="small">
          <el-form-item label="场所:" prop="homeId">
            <el-select v-model="inform.homeId" placeholder="请选择场所">
              <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称:" prop="deviceName">
            <el-input v-model="inform.deviceName" placeholder="请输入设备名称"></el-input>
          </el-form-item>
          <el-form-item label="设备编号:" prop="deviceSerial">
            <el-input v-model="inform.deviceSerial" placeholder="请输入设备编号"></el-input>
          </el-form-item>
          <el-form-item label="型号:" prop="model">
            <el-select v-model="inform.model" placeholder="请选择型号">
              <el-option v-for="item in dicObj.airModelData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
import {wgs84togcj02, gcj02towgs84} from '@/utils/positionUtil';//百度坐标与gps坐标转换方法
var vm;
export default {
  props: ['dicObj'],
  data() {
    const checkIPCode = (rule, value, callback) => {
      if (/^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/.test(value) == false) {
        callback(new Error("请输入正确的ip地址"));
      } else {
        callback();
      }
    }
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度
      categoryList: [],
      //form信息
      inform: {
        id: '',
        deviceName: '',//设备名称
        deviceSerial: '',//设备编码
        model: '',//设备类型
        homeId: '',//场所id
      },

      rules: {
        homeId: [
          {required: true, message: '请选择场所', trigger: "change"}
        ],
        deviceName: [
          {required: true, message: '请输入设备名称', trigger: "blur"}
        ],
        deviceSerial: [
          {required: true, message: '请输入设备编码', trigger: "blur"}
        ],
        model: [
          {required: true, message: '请选择设备型号', trigger: 'change'}
        ],
      },
    }
  },
  created() {
    vm = this
  },
  mounted() {

  },
  //方法
  methods: {
    //初始化
    init(type, item) {
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true
      vm.$nextTick(() => {
        if (type == 'add') {
          vm.dioTit = '新增设备'
        } else if (type == 'edit') {
          vm.dioTit = '编辑设备'
          vm.inform = {
            id: item.id,
            deviceName: item.deviceName,//设备名称
            deviceSerial: item.deviceSerial,//设备编码
            model: item.model,//设备型号
            homeId: item.homeId,//场所id
          }
        }
      })
    },
    //校验form
    checkForm(formEl) {
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if (vm.dioType == 'add') {
            vm.addDeviceAir()
          } else if (vm.dioType == 'edit') {
            vm.updateDeviceAir()
          }
        } else {
          return false
        }
      })
    },
    //关闭弹窗
    closeDio(formEl) {
      // vm.$nextTick(() => {
      vm.$refs[formEl].resetFields()
      // })
      vm.inform = {
        id: '',
        deviceName: '',//设备名称
        deviceSerial: '',//设备编码
        model: '',//设备型号
        homeId: '',//场所id
      }
      vm.isDio = false
    },
    //新增
    async addDeviceAir() {
      let dataObj = {
        'homeId': vm.inform.homeId,//场所id
        'deviceName': vm.inform.deviceName,//设备名称
        'deviceSerial': vm.inform.deviceSerial,//设备编号
        'model': vm.inform.model,//设备型号
      }
      const res = await apiUrl.addDeviceAir(dataObj)
      if (res?.code == 200) {
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateDeviceAir() {
      let dataObj = {
        'id': vm.inform.id,
        'homeId': vm.inform.homeId,//场所id
        'deviceName': vm.inform.deviceName,//设备名称
        'deviceSerial': vm.inform.deviceSerial,//设备编号
        'model': vm.inform.model,//设备型号
      }
      const res = await apiUrl.updateDeviceAir(dataObj)
      if (res?.code == 200) {
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.airAddEdit {
  .contDio {
    max-height: 500px;
    overflow: auto;
  }

  .uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }

      .iconJia {
        font-size: 28px;
        color: #8c939d;
        width: 88px;
        height: 116px;
        line-height: 116px;
        text-align: center;
      }

      .img {
        width: 88px;
        height: 116px;
        display: block;
      }
    }
  }
}
</style>