<template>
  <div class='safetyDevice mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name">
        <div>检索条件</div>
      </div>
      <el-form ref="searchForm" :model="keyWord" label-width="80px" inline @submit.native.prevent size="small">
        <template v-if="tabsActive=='2'">
          <el-form-item label="设备名称:" prop="name">
            <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入设备名称" clearable
                      @keyup.enter.native="toGetList(1)"/>
          </el-form-item>
          <el-form-item label="设备类型:" prop="type">
            <el-select  v-model="keyWord.type" placeholder="设备类型" class="w180"
                       @change="toGetList(1)" clearable>
              <el-option v-for="(item,index) in dicObj.typeDataPro" :key="index" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>

          </el-form-item>
        </template>
        <template v-if="tabsActive=='3'">
          <el-form-item label="设备名称:" prop="deviceName">
            <el-input type="text" class="w180" v-model="keyWord.deviceName" placeholder="请输入设备名称" clearable
                      @keyup.enter.native="toGetList(1)"/>
          </el-form-item>
          <el-form-item label="状态:" prop="status">
            <el-select v-model="keyWord.status" placeholder="选择状态" class="w100" @change="toGetList(1)" clearable>
              <el-option v-for="(item,index) in dicObj.statusData" :key="index" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-if="tabsActive=='1'">
          <el-form-item label="设备名称:" prop="devName">
            <el-input type="text" class="w180" v-model="keyWord.devName" placeholder="请输入设备名称" clearable
                      @keyup.enter.native="toGetList(1)"/>
          </el-form-item>
          <el-form-item label="设备编号:" prop="devNo" v-if="tabsActive=='1'">
            <el-input type="text" v-model="keyWord.devNo" class="w180" placeholder="请输入设备编号" clearable
                      @keyup.enter.native="toGetList(1)"/>
          </el-form-item>
          <el-form-item label="状态:" prop="status" v-if="tabsActive=='1'">
            <el-select v-model="keyWord.status" placeholder="选择状态" class="w100" @change="toGetList(1)" clearable>
              <el-option v-for="(item,index) in dicObj.statusData" :key="index" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-if="tabsActive=='4'">
          <el-form-item label="设备名称:" prop="deviceName">
            <el-input type="text" class="w180" v-model="keyWord.deviceName" placeholder="请输入设备名称" clearable
                      @keyup.enter.native="toGetList(1)"/>
          </el-form-item>
          <el-form-item label="序列号:" prop="deviceSerial">
            <el-input type="text" class="w180" v-model="keyWord.deviceSerial" placeholder="请输入设备序列号" clearable
                      @keyup.enter.native="toGetList(1)"/>
          </el-form-item>
          <el-form-item label="传感类型:" prop="senseType">
            <el-select v-model="keyWord.senseType" placeholder="选择传感类型" class="w180" clearable
                       @change="toGetList(1)">
              <el-option v-for="(item,index) in dicObj.typeDataSen" :key="index" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态:" prop="status">
            <el-select v-model="keyWord.status" placeholder="选择状态" class="w100" @change="toGetList(1)" clearable>
              <el-option v-for="(item,index) in dicObj.statusData" :key="index" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-if="tabsActive=='5'||tabsActive=='6'">
          <el-form-item label="设备名称:" prop="deviceName">
            <el-input type="text" class="w180" v-model="keyWord.deviceName" placeholder="请输入设备名称" clearable
                      @keyup.enter.native="toGetList(1)"/>
          </el-form-item>
          <el-form-item label="状态:" prop="status">
            <el-select v-model="keyWord.status" placeholder="选择状态" class="w100" @change="toGetList(1)" clearable>
              <el-option v-for="(item,index) in dicObj.statusData" :key="index" :label="item.dictName"
                         :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item label="">
          <el-button type="primary" @click="toGetList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-tabs v-model="tabsActive" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="电气设备" name="1">
        <electric-comp v-if="tabsActive=='1'" ref="eleCompRef" :dicObj="dicObj" :keyWord="keyWord"></electric-comp>
      </el-tab-pane>
      <el-tab-pane label="安防设备" name="2">
        <protection-comp v-if="tabsActive=='2'" ref="proCompRef" :dicObj="dicObj" :keyWord="keyWord"></protection-comp>
      </el-tab-pane>
      <el-tab-pane label="监控设备" name="3">
        <vidicon-comp v-if="tabsActive=='3'" ref="vidCompRef" :dicObj="dicObj" :keyWord="keyWord"></vidicon-comp>
      </el-tab-pane>
      <el-tab-pane label="传感设备" name="4">
        <sensor-comp v-if="tabsActive=='4'" ref="senCompRef" :dicObj="dicObj" :keyWord="keyWord"></sensor-comp>
      </el-tab-pane>
      <el-tab-pane label="空调设备" name="5">
        <air-comp v-if="tabsActive=='5'" ref="airCompRef" :dicObj="dicObj" :keyWord="keyWord"></air-comp>
      </el-tab-pane>
      <el-tab-pane label="UPS电源" name="6">
        <ups-comp v-if="tabsActive=='6'" ref="upsCompRef" :dicObj="dicObj" :keyWord="keyWord"></ups-comp>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import electricComp from './electric/index'
import protectionComp from './protection/index'
import vidiconComp from './vidicon/index'
import sensorComp from './sensor/index'
import airComp from './air/index'
import upsComp from './ups/index'
import breadCrumb from '@/components/bread-crumb.vue'
import {apiUrl} from '@/assets/js/api';
import qs from 'qs'

var vm;
export default {
  name: 'safety-device',
  data() {
    return {
      //筛选数据
      keyWord: {
        homeId: '',
        name: '',
        type: '',
        isRelay: ''
      },

      tabsActive: '1',//tabs选中

      //字典数据
      dicObj: {
        typeDataElt: [],//电气设备类型
        typeDataPro: [],//安防设备类型
        statusData: [],//在线状态
        connectData: [],//连接状态
        alarmData: [],//告警数据
        tripData: [],//开合闸数据
        homeList: [],//场所数据
        brandData: [],//设备品牌
        tagData: [],//标签数据
        platformData: [],//平台类型数据
        portData: [],//端口数据
        typeDataSen: [],//传感器类型数据
        numberData: [],//开关数据
        alarmTypeData: [],//告警类型
        ruleTypeData: [],//告警规则类型
        airTypeData: [],//空调设备类型
        airModelData: [],//空调设备型号
        upsModelData: [],//UPS设备型号
      },

      isAdmin: false,//是否是超级管理员
    }
  },
  components: {
    breadCrumb,
    electricComp,
    protectionComp,
    vidiconComp,
    sensorComp,
    airComp,
    upsComp,
  },
  watch: {
    '$store.state.homeId': function (newVal) {
      if (newVal !== '0') {
        vm.$nextTick(() => {
          vm.toGetList(1)
        })
      }
    },
  },
  async created() {
    vm = this
    await vm.getCurrDics()
    vm.deviceElectricTypeList()
    vm.deviceSecurityTypeList()
    vm.initHeight();
  },
  mounted() {
    vm.toGetList(1)
    window.onresize = () => {
      vm.initHeight()
    }
  },
  //方法
  methods: {
    //获取场所
    async getHome() {
      let dataObj = {
        'areaId': this.$store.state.csType
      }
      const res = await apiUrl.getHome(qs.stringify(dataObj))
      if (res?.code == 200) {
        this.dicObj.homeList = res.data
      }
    },
    toGetList(curr) {
      if (vm.$store.state.homeId != null) {
        this.keyWord.homeId = vm.$store.state.homeId;
        if (vm.tabsActive == '1') vm.$refs.eleCompRef.getList(curr)
        if (vm.tabsActive == '2') vm.$refs.proCompRef.getList(curr)
        if (vm.tabsActive == '3') vm.$refs.vidCompRef.getList(curr)
        if (vm.tabsActive == '4') vm.$refs.senCompRef.getList(curr)
        if (vm.tabsActive == '5') vm.$refs.airCompRef.getList(curr)
        if (vm.tabsActive == '6') vm.$refs.upsCompRef.getList(curr)
        vm.getHome()
      }
    },
    //tabs切换
    handleClick(tab, event) {
      vm.keyWord.type = ''
      vm.$nextTick(() => {
        vm.toGetList(1)
      })
    },
    //获取电气设备类型
    async deviceElectricTypeList() {
      const res = await apiUrl.deviceElectricTypeList()
      if (res?.code == 200) {
        vm.dicObj.typeDataElt = res.data;
      } else {
        vm.$message.error(res.message)
      }
    },
    //获取安防设备类型
    async deviceSecurityTypeList() {
      const res = await apiUrl.deviceSecurityTypeList()
      if (res?.code == 200) {
        vm.dicObj.typeDataPro = res.data;
      } else {
        vm.$message.error(res.message)
      }
    },
    //获取数据字典
    async getCurrDics() {
      let dataObj = ['DEVICE_STATUS', 'DEVICE_CONNECT', 'ALARM_CATEGORY', 'DEVICE_TRIP_STATUS', 'DOMAIN_DEVICE_BRAND', 'DEVICE_TAG',
        'PLATFORM_TYPE', 'COMM_PORT', 'SENSE_TYPE', 'SWITCH_NUMBER', 'ALARM_TYPE', 'ALARM_RULE_TYPE', 'DEVICE_TYPE',
        'AIR_CONDITION_MODEL','UPS_MODEL']
      const res = await apiUrl.getDicts(dataObj)
      if (res?.code == 200) {
        vm.dicObj.statusData = res.data.DEVICE_STATUS || [];//设备状态
        vm.dicObj.connectData = res.data.DEVICE_CONNECT || [];//连接状态
        vm.dicObj.alarmData = res.data.ALARM_CATEGORY || [];//告警类别
        vm.dicObj.tripData = res.data.DEVICE_TRIP_STATUS || [];//开合闸状态
        vm.dicObj.brandData = res.data.DOMAIN_DEVICE_BRAND || [];//设备品牌
        vm.dicObj.tagData = res.data.DEVICE_TAG || [];//设备标签
        vm.dicObj.platformData = res.data.PLATFORM_TYPE || [];//平台类型
        vm.dicObj.portData = res.data.COMM_PORT || [];//IO采集端口
        vm.dicObj.typeDataSen = res.data.SENSE_TYPE || [];//传感器类型
        vm.dicObj.numberData = res.data.SWITCH_NUMBER || [];//IO采集开关数量
        vm.dicObj.alarmTypeData = res.data.ALARM_TYPE || [];//告警类型
        vm.dicObj.ruleTypeData = res.data.ALARM_RULE_TYPE || [];//告警规则类型
        vm.dicObj.airTypeData = res.data.DEVICE_TYPE || [];//空调设备类型
        vm.dicObj.airModelData = res.data.AIR_CONDITION_MODEL || [];//空调设备型号
        vm.dicObj.upsModelData = res.data.UPS_MODEL || [];//UPS设备型号
        //剔除senseType=5和senseType=4 (IO采集器和串口服务器)
        const senseTypeList = vm.dicObj.typeDataSen.filter((item) => {
          return item.dictValue != 5 && item.dictValue != 4
        });
        vm.dicObj.typeDataSen = senseTypeList;
      } else {
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight() {
      var minWin = window.innerHeight;
      vm.tableH = minWin - 300
    }
  }
}
</script>
<style lang='scss'>
.safetyDevice {
  .el-tabs__content {
    padding: 10px 10px 0;
  }

  .el-checkbox-group {
    font-size: 14px;
  }

  .pageList {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: calc((100% - 45px) / 4);
      height: 149px;
      padding: 15px 10px;
      box-sizing: border-box;
      margin: 10px 7.5px;
      flex-shrink: 0;
      background: url('../../../assets/images/ui/item_bg.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      &:nth-child(4n) {
        margin-right: 0px;
      }

      &:nth-child(4n-3) {
        margin-left: 0px;
      }

      .el-checkbox {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        z-index: 1;

        .el-checkbox__input {
          display: none;
        }

        &.is-checked {
          .el-checkbox__input {
            display: block;
          }
        }

        .el-checkbox__label {
          display: none;
        }

        .el-checkbox__inner {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          left: 10px;
          top: 10px;

          &::after {
            left: 7px;
            width: 6px;
            height: 12px;
          }
        }
      }

      .liStatus {
        height: 19px;

        .status {
          border-right: 1px solid #1d4975;
          padding: 0 5px;
          position: relative;
          z-index: 1;

          &:last-child {
            border-right: none;
          }
        }
      }

      .liBtn {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(33, 97, 155, .75);
        padding: 5px;
        box-sizing: border-box;
        display: none;
        align-items: center;
        justify-content: space-around;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 3;

        .btn {
          padding: 5px;
          cursor: pointer;
          font-size: 18px;
          color: #00c6ff;

          &.el-icon-delete {
            color: #F56C6C;
          }

          &:hover {
            opacity: .8;
          }
        }
      }

      &:hover .liBtn {
        display: flex;
      }

      .liTips {
        color: #00c6ff;
        margin-top: 5px;
        display: flex;
        justify-content: space-around;
      }

      .liCont {
        display: flex;

        .el-image {
          width: 35%;
          height: 80px;
          flex-shrink: 0;
          margin-right: 5px;
          position: relative;
          z-index: 0;
        }

        .liRight {
          width: calc(100% - 35% - 5px);
          flex-shrink: 0;

          i {
            font-size: 30px;
            color: #00c6ff;
            cursor: pointer;
          }

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff;
            line-height: 24px;

            &.name {
              font-size: 14px;
              color: #00c6ff;
            }
          }
        }
      }

      &:hover {
        box-shadow: 0 0 3px 5px rgba(0, 0, 0, .15);
      }
    }
  }
}
</style>
