<template>
  <div class="airControl">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio()">
      <div class="contDio">
        <div class="num tc">16<sup>℃</sup></div>
        <div class="modeTit tc mb20">制冷模式</div>
        <div class="list modeList mb30">
          <div class="item">
            <i class="icon icon-xinhao3"></i><div class="name">风速</div>
          </div>
          <div class="item">
            <i class="icon icon-saofeng"></i><div class="name">风向</div>
          </div>
          <div class="item">
            <i class="icon icon-saofeng2"></i><div class="name">扫风</div>
          </div>
        </div>
        <div class="list btnList mb30">
          <div class="item" title="减"><i class="el-icon-minus"></i></div>
          <div class="item" title="开关"><i class="el-icon-switch-button error"></i></div>
          <div class="item" title="加"><i class="el-icon-plus"></i></div>
        </div>
        <div class="list btnList2 mb30">
          <div class="item" title="模式"><i class="icon icon-shuangmoshiqiehuan"></i></div>
          <div class="item" title="风速"><i class="icon icon-fengsu"></i></div>
        </div>
        <div class="list btnList2 mb30">
          <div class="item" title="风向"><i class="icon icon-saofeng"></i></div>
          <div class="item" title="扫风"><i class="icon icon-saofeng2"></i></div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="closeDio()" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
var vm;
export default {
  props: ['dicObj'],
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度
      
      //form信息
      inform: {
        id: '',
      },

      rules: {
        
      },
    }
  },
  created() {
    vm = this
  },
  mounted() {

  },
  //方法
  methods: {
    //初始化
    init(type, item) {
      vm.dioType = type
      vm.dioWidth = '440px'
      vm.dioTit = '遥控器'
      vm.isDio = true
      vm.$nextTick(() => {
        vm.inform.id = item.id
      })
    },
    //关闭弹窗
    closeDio(formEl) {
      vm.inform = {
        id: '',
      }
      vm.isDio = false
    },
    //修改
    async updateDevice() {
      let dataObj = {
        'id': vm.inform.id,
        'devName': vm.inform.devName,//设备名称
      }
      const res = await apiUrl.updateDevice(dataObj)
      if (res?.code == 200) {
        vm.$message.success('修改成功！')
      } else {
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.airControl {
  .contDio {
    max-height: 500px;
    overflow: auto;
    color: #fff;
    .num{
      font-size: 80px;
      margin-bottom: 10px;
      sup{
        font-size: 20px;
        margin-left: 10px;
      }
    }
    .list{
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      &.btnList2{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 40px;
        background-color: #031e44;
        box-shadow: 0 0px 5px 5px rgba(7, 130, 255, 0.55);
        .item{
          padding: 10px;
          cursor: pointer;
          i{
            font-size: 30px;
          }
        }
      }
      &.btnList{
        .item{
          padding: 10px;
          border-radius: 50%;
          background-color: #031e44;
          // border:1px solid #1d4975;
          box-shadow: 0 0px 5px 5px rgba(7, 130, 255, 0.55);
          cursor: pointer;
          i{
            font-size: 30px;
          }
          &:active{
            box-shadow: 0 0px 5px 5px rgba(7, 130, 255, 0.55) inset;
          }
        }
      }
      &.modeList{
        .item{
          text-align: center;
          i{
            font-size: 20px;
          }
          .name{
            margin-top: 10px;
          }
        }
      }
      
    }
  }
}
</style>